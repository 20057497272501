import Vue from 'vue';
import Vuex from 'vuex';

import EntryProxy from '@/app/proxies/EntryProxy';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {

    },

    state: {
        songs: [],
        queue: []
    },

    getters: {
        songs: state => state.songs,
        queue: state => state.songs.filter( song => song.played < song.requested ),

        getRandomQueued: state => state.queue[ Math.floor( Math.random() * state.queue.length ) ]
    },

    mutations: {
        setQueue: ( state, payload ) =>
        {
            state.songs = payload;
        },

        setNewList: ( state, payload ) =>
        {
            state.songs = payload;
        }
    },

    actions: {
        getAll: context =>
        {
            return new EntryProxy()
                .all()
                .then( response =>
                {
                    // console.log( 'response', response );
                    context.commit( 'setQueue', response );
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                })
        },

        update: ( context, payload ) =>
        {
            return new EntryProxy()
                .update( payload.id, payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                })
        },

        add: ( context, payload ) =>
        {
            return new EntryProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                })
        },

        newShuffle: ( context, payload ) =>
        {
            context.commit( 'setNewList', payload );
        }
    }
});
