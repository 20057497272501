<template>
    <div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        mounted()
        {
            // init
        }
    };
</script>
