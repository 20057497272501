<template>
    <div class="add-page">
        <h1>Add</h1>

        <div class="row">
            <div class="col-12 m-col-6">
                <div class="inputs">
                    <input v-model="videoData" placeholder="youtube id of url" />
                    <button @click="addVid">Add</button>
                </div>

                <p>Preview:</p>
                <youtube v-if="videoId" :video-id="videoId"></youtube>
            </div>

            <div class="col-12 m-col-6">
                <p>Queue:</p>
                <ul ref="queueContainer" class="queue-container">
                    <li v-for="song in queue"><a :href="'https://youtube.com/watch?v=' + song.video_id" target="_blank">link</a> - {{ song.name }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        components: {
        },

        data()
        {
            return {
                videoData: ''
            };
        },

        computed: {
            videoId()
            {
                let result = this.videoData;

                if( this.videoData.indexOf( 'youtube.com' ) > -1 )
                {
                    let url = new URL( this.videoData );
                    result = url.searchParams.get('v');
                }

                return result;
            },

            queue()
            {
                return this.$store.getters.queue;
            },

            songs()
            {
                return this.$store.getters.songs;
            }
        },

        watch: {
            videoData: pVal =>
            {
                // this.showPreview( pVal );
            }
        },

        mounted()
        {
            this.$store.dispatch( 'getAll' );
        },

        methods: {
            showPreview()
            {

            },

            async addVid()
            {
                let name = '';
                await this.$http( 'https://www.youtube.com/oembed?url=youtube.com/watch?v=' + this.videoId + '&format=json' ).then( result =>
                {
                    name = result.data.title;
                })

                let duplicate = this.songs.find( song => song.video_id === this.videoId );

                if( !duplicate )
                {
                    this.$store.dispatch( 'add', { video_id: this.videoId, played: 0, requested: 1, name: name }).then( result =>
                    {
                        if( result.id )
                        {
                            this.videoData = '';
                        }
                    });
                }
                else
                {
                    this.$store.dispatch( 'update', { id: duplicate.id, requested: duplicate.requested + 1, played: duplicate.played }).then( result =>
                    {
                        if( result.id )
                        {
                            this.videoData = '';
                        }
                    });
                }

                this.$store.dispatch( 'getAll' );
            }
        }
    };
</script>
