<template>
    <div class="player-page">
        <h1>Player</h1>
        <youtube ref="player" v-if="currentVid !== null" :video-id="currentVid.video_id" @ready="ready" @ended="ended" @error="error" :player-vars="{autoplay: 1, mute: 1}"></youtube>

        <button @click="skip">Skip</button>
        <button @click="shuffle">Shuffle</button>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Foo from '@/app/components/Foo';
    import { getTimeFromURL } from 'vue-youtube-embed';

    export default {
        components: {
            'v-foo': Foo
        },

        data()
        {
            return {
                appType: 'clean',
                currentVid: null,
                index: -1
            };
        },

        computed: {
            songs()
            {
                return this.$store.getters.songs;
            },

            queued()
            {
                return this.$store.getters.queue;
            },

            time()
            {
                return getTimeFromURL( 'https://youtube.com/watch?v=' + this.currentVid.video_id );
            }
        },

        mounted()
        {
            let hasIndex = localStorage.getItem( 'jukeboxIndex' );

            if( hasIndex )
            {
                this.index = hasIndex;
            }

            this.$store.dispatch( 'getAll' ).then( () =>
            {
                let unchangedSongList = ( parseInt( localStorage.getItem( 'jukeboxLength' ), 10 ) === this.songs.length );
                console.log( unchangedSongList, parseInt( localStorage.getItem( 'jukeboxLength' ), 10 ), this.songs.length );
                this.shuffle();

                if( unchangedSongList )
                {
                    this.currentVid = this.songs[ this.index ];
                }
                else
                {
                    this.currentVid = this.randomVid();
                }
            } );

            this.pauzeCheck = setInterval( this.pauzeCheck, 1000 );

            this.errorCheck = 0;
        },

        methods: {
            ready( e )
            {
                this.player = e.target;
                console.log( 'player', this.player );
            },

            ended()
            {
                this.index++;

                this.$store.dispatch( 'update', { id: this.currentVid.id, played: this.currentVid.played + 1 }).then( result =>
                {
                    this.currentVid = '';
                    this.$store.dispatch( 'getAll' ).then( result =>
                    {
                        this.currentVid = this.randomVid();
                    });
                } );
            },

            error()
            {
                console.log( 'errort' );
                this.$store.dispatch( 'update', { id: this.currentVid.id, played: this.currentVid.requested } ).then( result =>
                {
                    this.currentVid = '';
                    this.currentVid = this.randomVid();
                });
            },

            skip()
            {
                this.ended();
            },

            randomVid()
            {
                if( this.queued.length > 0 )
                {
                    return this.queued[ 0 ];
                }

                localStorage.setItem( 'jukeboxIndex', this.index );
                localStorage.setItem( 'jukeboxLength', this.songs.length );

                if( this.index === this.songs.length )
                {
                    console.log( 'SHUFFLE CALLED' );
                    this.shuffle();
                    this.index = 0;
                }

                console.log( this.songs );
                console.log( 'play index', this.index );
                console.log( this.songs[ this.index ].name );

                return this.songs[ this.index ];
            },

            pauzeCheck()
            {
                console.log( ( this.player.getCurrentTime() / this.player.getDuration() * 100 ) + '% played' );
                let playState = this.player.getPlayerState();

                if( playState === -1 )
                {
                    this.player.playVideo();

                    this.errorCheck += 1;
                }

                if( this.errorCheck > 10 && playState === -1 )
                {
                    this.errorCheck = 0;
                    this.skip();
                }

                if( this.player.getCurrentTime() === 0 )
                {
                    this.player.playVideo();
                    document.querySelector( 'video' ).play();
                }

                if( this.player.getCurrentTime() >= this.player.getDuration() )
                {
                    this.skip();
                }
            },

            shuffle()
            {
                this.$store.dispatch( 'newShuffle', this.shuffle( this.songs ) );
            },

            shuffle(originalArray){
              var array = [].concat(originalArray);
              var currentIndex = array.length, temporaryValue, randomIndex;

              // While there remain elements to shuffle...
              while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
              }

              return array;
            }
        }
    };
</script>
