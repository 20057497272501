import Vue from 'vue';
import Router from 'vue-router';

import Add from '@/app/pages/Add';
import Player from '@/app/pages/Player';
import List from '@/app/pages/List';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Add',
            component: Add
        },

        {
            path: '/player',
            name: 'Player',
            component: Player
        },

        {
            path: '/list',
            name: 'List',
            component: List
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
