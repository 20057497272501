<template>
    <div class="list-wrapper">
        <div class="player-container">
            <youtube ref="player" v-if="currentVid" :video-id="currentVid.video_id" @ready="ready" @ended="ended" @error="error" :player-vars="{autoplay: 1, mute: 1}"></youtube>
        </div>
        <div class="list-container">
            <ul ref="queueContainer" class="queue-container">
                <li v-for="(song, index) in songs">
                    <a :href="'https://youtube.com/watch?v=' + song.video_id" target="_blank">link</a><button @click="startSong( index )">Play</button> - {{ song.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .list-wrapper{
        width:100%;
        max-width:1600px;
        display:flex;
        justify-content:center;

        .player-container, .list-container{
            width:50%;
        }

        .player-container{
            align-self:stretch;

            &>div{
                position:fixed;
                top:1rem;
                left:1rem;
                max-width:50vw;
            }
        }

        .list-container{
            text-align:left;
            padding-left:1rem;

            ul{
                list-style-position:inside;
                list-style-type:none;
            }
        }
    }
</style>

<script>
    import Vue from 'vue';

    export default {
        data()
        {
            return {
                currentVid: null,
                index: 0
            };
        },

        computed: {
            songs()
            {
                return this.$store.getters.songs;
            },
            time()
            {
                return getTimeFromURL( 'https://youtube.com/watch?v=' + this.currentVid.video_id );
            }
        },

        mounted()
        {
            this.pauzeCheck = setInterval( this.pauzeCheck, 1000 );

            this.$store.dispatch( 'getAll' ).then( () =>{
                this.currentVid = this.nextVid();
            });
        },

        methods: {

            startSong( index )
            {
                console.log( 'startSong called', this.index - 1 );
                this.currentVid = '';
                this.index = index;

                Vue.nextTick( () =>
                {
                    console.log( 'change currentVid' );
                    this.currentVid = this.nextVid( this.index );
                });
            },

            nextVid( hardIndex )
            {
                console.log( 'nextVid pls' );
                this.index++;
                if( hardIndex )
                {
                    this.index = hardIndex;
                }

                this.currentVid = '';

                if( this.index === this.songs.length )
                {
                    this.index = 0;
                }

                setTimeout( () =>
                {
                    this.currentVid = this.songs[ this.index ];
                }, 100 );
            },

            ready( e )
            {
                this.player = e.target;
            },

            ended()
            {
                this.nextVid();
            },

            error()
            {
                console.log( 'errort' );
                this.nextVid();
            },

            skip()
            {
                this.ended();
            },

            pauzeCheck()
            {
                let playState = this.player.getPlayerState();

                this.lastCurrentTime = this.currentTime;
                this.currentTime = this.player.getCurrentTime();

                if( this.lastCurrentTime === this.currentTime )
                {
                    this.timesTheSame++;

                    if( this.timesTheSame > 5 )
                    {
                        this.timesTheSame = 0;
                        this.nextVid();
                    }
                }
                else
                {
                    this.timesTheSame = 0;
                }

                if( parseInt( ( this.player.getCurrentTime() / this.player.getDuration() * 100 ), 10 ) >= 99 )
                {
                    this.nextVid();
                }

                if( playState === -1 )
                {
                    this.player.playVideo();

                    this.errorCheck += 1;
                }

                if( this.errorCheck > 10 && playState === -1 )
                {
                    this.errorCheck = 0;
                    this.skip();
                }

                if( this.player.getCurrentTime() === 0 )
                {
                    this.player.playVideo();
                    document.querySelector( 'video' ).play();
                }

                if( this.player.getCurrentTime() >= this.player.getDuration() )
                {
                    this.skip();
                }
            }
        }
    };
</script>
